<template>
  <div class="row">
    <div class="col d-flex">
      <icon name="info-circle" color="orange" width="12" height="12"/>
      <small :class="color">
        Your chosen password must be at least 8
        characters long and contain at least 1 lowercase letter,
        1 uppercase letter, 1 number, and 1 special character.
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordTip',

  props: {
    color: { type: String, default: 'white' },
  },
};
</script>

<style lang="less" scoped>
.row {
  margin-top: 1rem;
  margin-bottom: 1rem;

  small {
    flex: 1;
    display: block;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 0.5rem;
  }

  svg { margin-top: .15rem; }
}
</style>
