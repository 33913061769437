<template>
  <div class="col-4 offset-4">

    <div class="row">
      <div class="col mt-7">
        <h1>Notification Preferences</h1>
      </div>
    </div>

    <div class="row">
      <div class="col mt-6">

        <div class="row">
          <div class="col-12 p-5 bg-medium-tan">
            <div class="row my-3">
              <div class="col">
                <h2>Flight Notifications</h2>
                <span>Check which notifications you want to receive an email for.</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="preference-item">
                  <span>Email Notifications</span>
                  <b-checkbox
                    id="flight-notification-checkbox"
                    v-model="prefs.flight"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col mt-5 text-right pr-0">
            <router-link
              tag="button"
              to="/flights"
              class="tertiary">
              cancel
            </router-link>
            <Button
              @click.native="trySavePrefs"
              class="primary"
              :disabled="!prefsWereModified"
              :is-loading="isLoading">
                update
              </Button>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showModal"
      hide-header
      lazy
      centered
      no-close-on-backdrop
      bodyClass="p-4">
      <div class="close">
        <icon name="remove" color="medium-tan" @click.native="toggleModal" />
      </div>

      <p class="p-4">
        Warning: If you turn off notifications, you will <strong>NOT</strong> receive notifications
        when you've been invited to a flight that is starting. We don't want you to miss out!
      </p>

      <template v-slot:modal-footer>
        <div class="w-100 p-4">
          <button class="tertiary" @click="toggleModal">Cancel</button>
          <button class="primary" @click="savePrefs">Turn Off</button>
        </div>
    </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { deepEquals } from '@balloonplatform/client-lib/lib/js/utils';

export default {
  name: 'NotificationPreferencesView',

  data() {
    return {
      isLoading: true,
      showModal: false,
      showConfirmation: false,
      prefs: {
        flight: true,
      },
    };
  },

  computed: {
    ...mapState({
      notificationPrefs: (state) => state.users.loggedInUser.preferences.notifications,
    }),

    prefsWereModified() {
      return !deepEquals(this.prefs, this.notificationPrefs);
    },
  },

  created() {
    this.resetData();
  },

  methods: {
    resetData() {
      this.prefs = { ...this.notificationPrefs };
      this.isLoading = false;
    },

    toggleModal(value) {
      this.showModal = typeof value === 'boolean' ? value : !this.showModal;
    },

    trySavePrefs() {
      if (!this.prefsWereModified) {
        return;
      }

      if (this.prefs.flight) {
        this.savePrefs();
        return;
      }

      this.showModal = true;
    },

    async savePrefs() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('saveNotificationPrefs', this.prefs);
        this.$store.dispatch('successToast', 'Your preferences have been updated!');
      } catch (err) {
        this.$store.dispatch('errorToast', err.message);
      } finally {
        this.isLoading = false;
        this.showModal = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2.2rem;
  font-weight: 900;
  line-height: 1.36;
  margin: 0 0 .8rem 0;
}

.preference-item {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px @tan;

  span {
    font-size: 1.4rem;
    font-weight: 500;;
    line-height: 1.29;
  }
}
</style>
