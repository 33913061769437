<template>
  <div class="col-4 offset-4">
    <div class="row">
      <div class="col mt-7">
        <h1>Change your password</h1>
      </div>
    </div>

    <div class="row">
      <div class="col bg-medium-tan mt-6">
        <PasswordForm />
      </div>
    </div>
  </div>
</template>

<script>
import PasswordForm from '@/components/Password/PasswordForm.vue';

export default {
  name: 'ChangePasswordView',

  components: { PasswordForm },
};
</script>
