<template>
  <form class="col" @submit.prevent="submitPassword">
    <div class="row">
      <div class="col input-group navy" :class="{'invalid': $v.password.$error }">
        <b-input
          type="password"
          v-focus
          v-model.trim="$v.password.$model"
          lazy-formatter
          id="password-input"
          ref="password-input"
          aria-label="New Password"
          placeholder="New Password"
        />
        <Error>
          <div>
            <span v-if="!$v.password.required">Password is required</span>
            <span v-else-if="!$v.password.password">
              Your password is not secure enough, check the requirements below.
            </span>
          </div>
        </Error>
      </div>
    </div>
    <div class="row">
      <div class="col input-group navy" :class="{'invalid': $v.confirmedPassword.$error }">
        <b-input
          type="password"
          v-model.trim="$v.confirmedPassword.$model"
          lazy-formatter
          id="confirm-password-input"
          placeholder="Confirm New Password"
          aria-label="Confirm New Password"
        />
        <Error>
          <div>
            <span v-if="!$v.confirmedPassword.required">
              You need to confirm the password
            </span>
            <span v-else-if="!$v.confirmedPassword.sameAsPassword">
              Passwords don't match
            </span>
          </div>
        </Error>
      </div>
    </div>

    <PasswordTip color="navy" class="mt-5" />

    <div class="row" v-if="!isSuccess">
      <div class="col my-5 text-right">
        <router-link
          tag="button"
          class="tertiary"
          v-if="isLoggedIn"
          to="/flights" >
          cancel
        </router-link>
        <Button
          type="submit"
          class="primary"
          :class="{ lg: !isLoggedIn }"
          :is-loading="isLoading"
          :disabled="$v.$invalid">
            submit
        </Button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';
import { password } from '@/utils/custom-validators';

import PasswordTip from '@/components/Password/PasswordTip.vue';

export default {
  name: 'SetPasswordForm',
  mixins: [validationMixin],

  components: { PasswordTip },
  props: {
    token: { type: String, default: null },
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      password: '',
      confirmedPassword: '',
    };
  },

  validations: {
    password: { required, password },
    confirmedPassword: { required, sameAsPassword: sameAs('password') },
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'isCredentialed']),
  },
  created() {
    if (!this.isCredentialed) {
      this.checkToken();
    }
  },
  methods: {
    async checkToken() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('tokenCheckPassword', this.token);
      } catch (err) {
        this.$store.dispatch('errorToast', err.message);
        this.$router.push({ name: 'forgotPassword' });
      } finally {
        this.isLoading = false;
      }
    },
    async submitPassword() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('changePassword', {
          password: this.password,
          token: this.token,
        });
        this.isSuccess = true;
        this.$router.push('/');
      } catch (err) {
        this.$store.dispatch('errorToast', err.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
